import { useEffect } from "react";
import { API_OPTIONS } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { addPopularMovies } from "../utils/moviesSlice";

const usePopularMovies = () => {
  const dispatch = useDispatch();
  const popularMovies = useSelector((store) => store.movies.popularMovies);

  const getPopularList = async () => {
    const response = await fetch(
      "https://api.themoviedb.org/3/movie/popular?&page=1",
      API_OPTIONS,
    );
    const data = await response.json();
    dispatch(addPopularMovies(data.results));
  };

  useEffect(() => {
    !popularMovies && getPopularList();
    // eslint-disable-next-line
  }, [popularMovies]);
};

export default usePopularMovies;
